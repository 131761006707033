"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TypeAnalyticsEnum = exports.ExpensesTypeEnum = exports.ConfirmationEnum = exports.StateReceptionEnum = exports.PaymentEnum = exports.PaymentStatusEnum = exports.DiscountEnum = exports.CategoriesEnum = exports.CurrencyEnum = exports.TypeOfficeEnum = exports.DayEnum = exports.SpecializationEnum = exports.DefaultEventTypesEnum = exports.PurposeVisitEnum = exports.InteractionUserEnum = exports.AccountRoleEnum = exports.EventTypeEnum = exports.PossibilityDiscountEnum = exports.SexEnum = exports.DefaultResponseCodeEnum = exports.GmailAnalyticsEnum = void 0;
var GmailAnalyticsEnum;
(function (GmailAnalyticsEnum) {
    GmailAnalyticsEnum["EMAILS_SENT"] = "EMAILS_SENT";
    GmailAnalyticsEnum["EMAILS_RECEIVED"] = "EMAILS_RECEIVED";
})(GmailAnalyticsEnum || (exports.GmailAnalyticsEnum = GmailAnalyticsEnum = {}));
var DefaultResponseCodeEnum;
(function (DefaultResponseCodeEnum) {
    DefaultResponseCodeEnum["SUCCESS"] = "SUCCESS";
    DefaultResponseCodeEnum["ERROR"] = "ERROR";
})(DefaultResponseCodeEnum || (exports.DefaultResponseCodeEnum = DefaultResponseCodeEnum = {}));
var SexEnum;
(function (SexEnum) {
    SexEnum["MEN"] = "MEN";
    SexEnum["WOMEN"] = "WOMEN";
})(SexEnum || (exports.SexEnum = SexEnum = {}));
var PossibilityDiscountEnum;
(function (PossibilityDiscountEnum) {
    PossibilityDiscountEnum["YES"] = "YES";
    PossibilityDiscountEnum["NO"] = "NO";
})(PossibilityDiscountEnum || (exports.PossibilityDiscountEnum = PossibilityDiscountEnum = {}));
var EventTypeEnum;
(function (EventTypeEnum) {
    EventTypeEnum["VISIT"] = "VISIT";
    EventTypeEnum["CUSTOM"] = "CUSTOM";
    EventTypeEnum["EVENT"] = "EVENT";
})(EventTypeEnum || (exports.EventTypeEnum = EventTypeEnum = {}));
var AccountRoleEnum;
(function (AccountRoleEnum) {
    AccountRoleEnum["ADMINISTRATOR"] = "ADMINISTRATOR";
    AccountRoleEnum["DOCTOR"] = "DOCTOR";
    AccountRoleEnum["MANAGER"] = "MANAGER";
})(AccountRoleEnum || (exports.AccountRoleEnum = AccountRoleEnum = {}));
var InteractionUserEnum;
(function (InteractionUserEnum) {
    InteractionUserEnum["PHONE"] = "PHONE";
    InteractionUserEnum["VIBER"] = "VIBER";
    InteractionUserEnum["SMS"] = "SMS";
    InteractionUserEnum["EMAIL"] = "EMAIL";
    InteractionUserEnum["VISIT"] = "VISIT";
    InteractionUserEnum["SERVISE"] = "SERVISE";
})(InteractionUserEnum || (exports.InteractionUserEnum = InteractionUserEnum = {}));
var PurposeVisitEnum;
(function (PurposeVisitEnum) {
    PurposeVisitEnum["CONSULTATION"] = "CONSULTATION";
    PurposeVisitEnum["HELLP"] = "HELLP";
    PurposeVisitEnum["TREATMENT"] = "TREATMENT";
    PurposeVisitEnum["OTHER"] = "OTHER";
    PurposeVisitEnum["VISIT"] = "VISIT";
})(PurposeVisitEnum || (exports.PurposeVisitEnum = PurposeVisitEnum = {}));
var DefaultEventTypesEnum;
(function (DefaultEventTypesEnum) {
    DefaultEventTypesEnum["TRANSACTION"] = "TRANSACTION";
})(DefaultEventTypesEnum || (exports.DefaultEventTypesEnum = DefaultEventTypesEnum = {}));
var SpecializationEnum;
(function (SpecializationEnum) {
    SpecializationEnum["THERAPIST"] = "THERAPIST";
    SpecializationEnum["ORTHODONTIST"] = "ORTHODONTIST";
    SpecializationEnum["SURGEON"] = "SURGEON";
    SpecializationEnum["PERIODONTIST"] = "PERIODONTIST";
    SpecializationEnum["ENDODONTIST"] = "ENDODONTIST";
    SpecializationEnum["IMPLANTOLOGIST"] = "IMPLANTOLOGIST";
    SpecializationEnum["PROSTHETIST"] = "PROSTHETIST";
})(SpecializationEnum || (exports.SpecializationEnum = SpecializationEnum = {}));
var DayEnum;
(function (DayEnum) {
    DayEnum["MON"] = "mon";
    DayEnum["TUE"] = "tue";
    DayEnum["WED"] = "wed";
    DayEnum["THU"] = "thu";
    DayEnum["FRI"] = "fri";
    DayEnum["SAT"] = "sat";
    DayEnum["SUN"] = "sun";
})(DayEnum || (exports.DayEnum = DayEnum = {}));
var TypeOfficeEnum;
(function (TypeOfficeEnum) {
    TypeOfficeEnum["THERAPEUTIC"] = "THERAPEUTIC";
    TypeOfficeEnum["SURGICAL"] = "SURGICAL";
    TypeOfficeEnum["ORTHODONTIC"] = "ORTHODONTIC";
    TypeOfficeEnum["PERIODONTIC"] = "PERIODONTIC";
    TypeOfficeEnum["ENDODONTIC"] = "ENDODONTIC";
    TypeOfficeEnum["IMPLANTOLOGIC"] = "IMPLANTOLOGIC";
    TypeOfficeEnum["PROSTHETIC"] = "PROSTHETIC";
})(TypeOfficeEnum || (exports.TypeOfficeEnum = TypeOfficeEnum = {}));
var CurrencyEnum;
(function (CurrencyEnum) {
    CurrencyEnum["UAH"] = "UAH";
    CurrencyEnum["USD"] = "USD";
    CurrencyEnum["EUR"] = "EUR";
})(CurrencyEnum || (exports.CurrencyEnum = CurrencyEnum = {}));
var CategoriesEnum;
(function (CategoriesEnum) {
    CategoriesEnum["THERAPY"] = "THERAPY";
    CategoriesEnum["ORTHOPEDICS"] = "ORTHOPEDICS";
    CategoriesEnum["ORTHODONTICS"] = "ORTHODONTICS";
    CategoriesEnum["X_RAY_DIAGNOSIS"] = "X_RAY_DIAGNOSIS";
    CategoriesEnum["SURGERY"] = "SURGERY";
})(CategoriesEnum || (exports.CategoriesEnum = CategoriesEnum = {}));
var DiscountEnum;
(function (DiscountEnum) {
    DiscountEnum["PENSIONER"] = "PENSIONER";
    DiscountEnum["VETERANS"] = "VETERANS";
    DiscountEnum["STUDENTS"] = "STUDENTS";
})(DiscountEnum || (exports.DiscountEnum = DiscountEnum = {}));
var PaymentStatusEnum;
(function (PaymentStatusEnum) {
    PaymentStatusEnum["PAID"] = "PAID";
    PaymentStatusEnum["UNPAID"] = "UNPAID";
    PaymentStatusEnum["PARTIALLYPAID"] = "PARTIALLYPAID";
})(PaymentStatusEnum || (exports.PaymentStatusEnum = PaymentStatusEnum = {}));
var PaymentEnum;
(function (PaymentEnum) {
    PaymentEnum["CASH"] = "CASH";
    PaymentEnum["CARD"] = "CARD";
    PaymentEnum["CASHLESS"] = "CASHLESS";
    PaymentEnum["INSURANCE"] = "INSURANCE";
})(PaymentEnum || (exports.PaymentEnum = PaymentEnum = {}));
var StateReceptionEnum;
(function (StateReceptionEnum) {
    StateReceptionEnum["SCHEDULED"] = "SCHEDULED";
    StateReceptionEnum["CONFIRMED"] = "CONFIRMED";
    StateReceptionEnum["RESCHEDULED"] = "RESCHEDULED";
    StateReceptionEnum["CANCELLED"] = "CANCELLED";
    StateReceptionEnum["COMPLETED"] = "COMPLETED";
})(StateReceptionEnum || (exports.StateReceptionEnum = StateReceptionEnum = {}));
var ConfirmationEnum;
(function (ConfirmationEnum) {
    ConfirmationEnum["PHONE"] = "PHONE";
    ConfirmationEnum["SMS"] = "SMS";
    ConfirmationEnum["EMAIL"] = "EMAIL";
})(ConfirmationEnum || (exports.ConfirmationEnum = ConfirmationEnum = {}));
var ExpensesTypeEnum;
(function (ExpensesTypeEnum) {
    ExpensesTypeEnum["MATERIALS"] = "MATERIALS";
    ExpensesTypeEnum["SURGEON"] = "SURGEON";
    ExpensesTypeEnum["RENT"] = "RENT";
    ExpensesTypeEnum["SALARY"] = "SALARY";
    ExpensesTypeEnum["OTHER"] = "OTHER";
})(ExpensesTypeEnum || (exports.ExpensesTypeEnum = ExpensesTypeEnum = {}));
var TypeAnalyticsEnum;
(function (TypeAnalyticsEnum) {
    TypeAnalyticsEnum["FINNS"] = "FINNS";
    TypeAnalyticsEnum["SERVISE"] = "SERVISE";
    TypeAnalyticsEnum["ACCOUNT"] = "ACCOUNT";
    TypeAnalyticsEnum["USERS"] = "USERS";
    TypeAnalyticsEnum["EQUIPMENT"] = "EQUIPMENT";
})(TypeAnalyticsEnum || (exports.TypeAnalyticsEnum = TypeAnalyticsEnum = {}));
