"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var shared_1 = require("@space-start/shared");
var trpc_1 = require("@web/lib/trpc");
var react_1 = require("react");
var react_i18next_1 = require("react-i18next");
var useFormData = function (selectedAddressId) {
    var t = (0, react_i18next_1.useTranslation)().t;
    var doctors = trpc_1.trpc.account.list.useQuery().data;
    var clients = trpc_1.trpc.user.list.useQuery().data;
    var services = trpc_1.trpc.service.list.useQuery().data;
    var addresses = trpc_1.trpc.address.getAllOfficeAndAddress.useQuery().data;
    var addressOptions = (addresses === null || addresses === void 0 ? void 0 : addresses.map(function (address) { return ({
        label: address.fullTitle,
        value: address.id
    }); })) || [];
    var cabinetOptions = (0, react_1.useMemo)(function () {
        var _a;
        var selectedAddress = addresses === null || addresses === void 0 ? void 0 : addresses.find(function (address) { return address.id === Number(selectedAddressId); });
        return (((_a = selectedAddress === null || selectedAddress === void 0 ? void 0 : selectedAddress.offices) === null || _a === void 0 ? void 0 : _a.map(function (office) { return ({
            label: office.title,
            value: office.id
        }); })) || []);
    }, [addresses, selectedAddressId]);
    var servicesOptions = (services === null || services === void 0 ? void 0 : services.map(function (service) { return ({
        value: service.id,
        label: "".concat(service.numberService, " ").concat(service.name),
        price: service.price,
        possibilityDiscount: service.possibilityDiscount
    }); })) || [];
    var doctorsOptions = (doctors === null || doctors === void 0 ? void 0 : doctors.map(function (doctor) { return ({
        value: doctor.id,
        label: "".concat(doctor.firstName, " ").concat(doctor.lastName)
    }); })) || [];
    var clientsOptions = (clients === null || clients === void 0 ? void 0 : clients.map(function (client) { return ({
        value: client.id,
        label: "".concat(client.firstName, " ").concat(client.lastName)
    }); })) || [];
    var paymentStatusOptions = Object.values(shared_1.PaymentStatusEnum).map(function (value) { return ({
        value: value,
        label: t("".concat("paymentStatus", ".").concat(value))
    }); });
    var paymentOptions = Object.entries(shared_1.PaymentEnum).map(function (_a) {
        var value = _a[0];
        return ({
            label: t("payment.".concat(value)),
            value: value
        });
    });
    return {
        servicesOptions: servicesOptions,
        doctorsOptions: doctorsOptions,
        clientsOptions: clientsOptions,
        paymentStatusOptions: paymentStatusOptions,
        paymentOptions: paymentOptions,
        addressOptions: addressOptions,
        cabinetOptions: cabinetOptions
    };
};
exports.default = useFormData;
